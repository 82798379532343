import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-contextual-menu',
    templateUrl: './contextual-menu.component.html',
    styleUrls: ['./contextual-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatButtonModule, MatMenuModule, MatIconModule]
})
export class ContextualMenuComponent {
  @Input() icon: string = 'more_vert';
  @Input() index: number;
  @Input() disabled: boolean = false;
  @Output() clickEvent = new EventEmitter();
}
